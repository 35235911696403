import React, { useState, useEffect } from "react"

const Posts = () => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const req = await fetch(
                "https://blog_worker.rohanrk2009.workers.dev/posts/"
            );
            const resp = await req.json();
            setPosts(resp);
        }
        getPosts()
    }, []);

    return (
        <div>
            <h1>Posts</h1>
            {posts.map((post) => (
                <div key={post.id}>
                    <h2>{post.title}</h2>
                    <p>{post.body}</p>
                 </div>
            ))}
        </div>
    );
};

export default Posts;